import { Typography } from "antd"

function AppFooter(){
    return <div className="AppFooter" style={{ display: 'flex', justifyContent: 'space-between', padding: '0 24px' }}>
        <Typography.Link>+353 88888888</Typography.Link>
        <Typography.Link>Copy Right @GetDynamic</Typography.Link>
        <Typography.Link>User Terms</Typography.Link>
    </div>
}

export default AppFooter