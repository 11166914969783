import { Typography, Space, Statistic,Card, Row, Col, Table, Select,Tooltip} from "antd";
import{FundViewOutlined, DollarOutlined, ShoppingCartOutlined, AimOutlined, GooglePlusOutlined,
    YoutubeOutlined, LinkedinOutlined, FacebookOutlined, TikTokOutlined} from "@ant-design/icons"

import React, { useEffect, useState } from 'react';
import apiClient from '../../utils/apiClient'


function Dashboard(){

    const [dashboardData, setDashboardData] = useState({
        roas:'',
        saleAll: '',
        costAll: '',
        ROAS: '',
        profitAll: ''
    });

    useEffect(() => {
      apiClient.get('getManagerOverALl')
          .then(response => {
              setDashboardData(response.data);
          })
          .catch(error => {
              console.error('Error fetching data: ', error);
          });
  }, []);


    return(
    <div className="DashboardPage">
        <Typography.Title>Overall Performance</Typography.Title>
        <Space direction="vertical">
            <Space direction="hrizontal" size={"large"} block="true">
                <DashboardCard title="Sales" value={dashboardData.saleAll}  icon={ <FundViewOutlined />} color="red"></DashboardCard>
                <DashboardCard title="Investment" value={dashboardData.costAll}  icon={ <AimOutlined />}></DashboardCard>
                <DashboardCard title="ROAS" value={dashboardData.roas}  icon={<ShoppingCartOutlined />}></DashboardCard>
                <DashboardCard title="Profit" value={dashboardData.profitAll}  icon={ <DollarOutlined />}></DashboardCard>
            </Space>
            {/* <Space direction="vertical">
                <h1>Expenses</h1>
                <Space direction="hrizontal">
                    <PlatformsData icon={<GooglePlusOutlined style={{fontSize:34}}></GooglePlusOutlined>} value="8%"></PlatformsData>
                    <PlatformsData icon={<YoutubeOutlined style={{fontSize:34}}></YoutubeOutlined>} value="8%"></PlatformsData>
                    <PlatformsData icon={<LinkedinOutlined style={{fontSize:34}}></LinkedinOutlined>} value="8%"></PlatformsData>
                    <PlatformsData icon={<FacebookOutlined style={{fontSize:34}}></FacebookOutlined>} value="8%"></PlatformsData>
                    <PlatformsData icon={<TikTokOutlined style={{fontSize:34}}></TikTokOutlined>} value="8%"></PlatformsData>
                </Space>
            </Space> */}

            <Space direction="vertical">
              <h1>Target Achievement</h1>
              {/* <Space direction="hrizontal">
                <PlatformsData title="Salse Target" value="100%"></PlatformsData>
                <PlatformsData title="Salse YTD" value="1000000%"></PlatformsData>
                <PlatformsData title="Target to Close" value="10000000000000%"></PlatformsData>
                <PlatformsData title="Run-Rate" value="100%"></PlatformsData>
              </Space> */}
              <TargetAchievement />
            </Space>  

              <Space direction="vertical">
              <h1>Monthly Achievement</h1>
              <MonthlyTargetAchievement />
              </Space>
              <Space direction="vertical">
              <h1>Account Performance</h1>
              <AccountPerformanceTable />
              </Space>
        </Space>
    </div>
    )
}


function DashboardCard({title, value, icon,color}){
    return(
        <Card style={{
            background:{color}
            }} className="DashboardCard" hoverable="true">
            <Space direction="hrizontal">
                {icon}
                <Statistic title={title} value={value}></Statistic>
            </Space>
         </Card>
    );
}


const cardStyle = {
  borderRadius: '10px', // 设置圆角
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // 一些阴影效果
  margin: '10px',
};

const TargetAchievement = () => {
  // 假设这是你的数据对象
  const data = {
    salesTarget: 'DKK 50,000,000',
    salesYTD: 'DKK 10,000,000',
    targetToClose: 'DKK 40,000,000',
    runRate: '98.7%'
  };

  return (
    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
      <Col span={6}>
        <Card title="Sales Target" bordered={false} style={cardStyle} hoverable="true">
          {data.salesTarget}
        </Card>
      </Col>
      <Col span={6}>
        <Card title="Sales YTD" bordered={false} style={cardStyle} hoverable="true">
          {data.salesYTD}
        </Card>
      </Col>
      <Col span={6}>
        <Card title="Target to Close" bordered={false} style={cardStyle} hoverable="true">
          {data.targetToClose}
        </Card>
      </Col>
      <Col span={6}>
        <Card title="Run-Rate" bordered={false} style={cardStyle} hoverable="true">
          {data.runRate}
        </Card>
      </Col>
    </Row>
  );
};





const MonthlyTargetAchievement = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    apiClient.get('accountMonthlyDetail')
      .then(response => {
        setTableData(response.data); // 直接从 response.data 获取数据
      })
      .catch(error => {
        console.error('Failed to fetch:', error);
      });
  }, []);

  const columnWidth = 75; 
  // 根据获取的数据动态创建列
  const columns = tableData.length > 0 ? Object.keys(tableData[0]).map(month => ({
    title: month,
    dataIndex: month,
    key: month,
    width: columnWidth, // 设置固定宽度
    // 当内容过长被截断时，通过Tooltip显示完整内容
    render: text => (
      <Tooltip placement="topLeft" title={text}>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: columnWidth }}>
          {text}
        </div>
      </Tooltip>
    ),
  })) : [];
  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      bordered
      scroll={{ x: 'max-content' }} // 使表格横向滚动
    />
  );
};





//Account performance
const { Option } = Select;

const AccountPerformanceTable = () => {

  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 假设API端点是'/api/account-performance'
        const response = await apiClient.get('getSubAccountsPerformance');
        setAccountData(response.data); // 假设response.data是账户数据数组
      } catch (error) {
        console.error('Error fetching data: ', error);
        // 在这里处理错误，例如通过状态更新UI，通知用户发生了错误
      }
    };

    fetchData();
  }, []);
  const columns = [
    {
      title: 'Account',
      dataIndex: 'customerName',
      key: 'account',
    },
    {
      title: 'Id',
      dataIndex: 'customerId',
      key: 'id',
    },
    {
      title: 'Sales',
      dataIndex: 'salse',
      key: 'sales',
    },
    {
      title: 'Investment',
      dataIndex: 'cost',
      key: 'investment',
    },
    {
      title: 'ROAS',
      dataIndex: 'roas',
      key: 'roas',
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
    },
  ];

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        Select Currency:
        <Select defaultValue="DKK" style={{ width: 120 }}>
          <Option value="DKK">DKK</Option>
          {/* Add more <Option> tags for other currencies */}
        </Select>
      </Space>
      <Table columns={columns} dataSource={accountData} pagination={false} />
    </>
  );
};




              

export default Dashboard;