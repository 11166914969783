import { Typography, Form, Input, Button, message } from "antd";
import { useNavigate } from 'react-router-dom';

export default function Register() {
    const navigate = useNavigate();

    const handleRegister = async (values) => {
        const { username, password, email , registrationCode} = values;
        const payload = {
            user: {
                username,
                password,
                email
            },
            registrationCode
        };
        const baseURL = process.env.REACT_APP_API_URL;
        console.log(values);
        console.log(payload);
        try {
            const response = await fetch(baseURL+'createUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            // if (!response.ok) {
            //     throw new Error('Registration failed!');
            // }

            const data = await response.json();
            console.log(data.success);
            if (data.success) {
                message.success('Registration successful!');
                navigate('/login'); // Redirect to login page or home page after registration
            } else {
                message.error(data.message || 'Registration failed');
                // message.error(data.code);
            }
        } catch (error) {
            // message.error("Registration error: " + error.message);
            message.error(error.message);
        }
    }

    const handleRegisterClick = () => {
        navigate('/login');
    };

    // 密码校验规则
    const passwordRules = [
        { required: true, message: 'Please enter your password!' },
        {
            min: 8,
            message: '* Password must be at least 8 characters long!'
        },
        {
            pattern: /[A-Z]/,
            message: '* Password must contain at least one uppercase letter!'
        },
        {
            pattern: /[!@#$%^&*(),.?":{}|<>]/,
            message: '* Password must contain at least one special character!'
        }
    ];

    return (
        <div>
            <Typography.Title>Register Page</Typography.Title>
            <div style={{ maxWidth: 300, margin: '0 auto', marginTop: 50 }}>
                <Form
                    name="register"
                    initialValues={{ remember: true }}
                    onFinish={handleRegister}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please enter your username!' }]}
                    >
                        <Input placeholder="User Name" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={passwordRules}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter your email!' },
                            { type: 'email', message: 'The input is not valid E-mail!' }
                        ]}
                    >
                        <Input placeholder="Email Address" />
                    </Form.Item>
                    <Form.Item
                        name="registrationCode"
                        rules={[{ required: true, message: 'Please enter your registration code!' }]}
                    >
                        <Input placeholder="Registration Code" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Register
                        </Button>
                    </Form.Item>

                    <Form.Item>
                    <Button type="link" block onClick={handleRegisterClick}>
                        Login
                    </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}