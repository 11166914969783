import { Typography, Table, Layout, Button, Modal, Form, Input, Select, Row,Col, message, notification, Spin} from "antd";
import React, { useState, useEffect , useRef} from 'react';
import apiClient from '../../utils/apiClient'
import { useNavigate } from 'react-router-dom';
import StyledCard from "../../components/StyledCard";

const { Content } = Layout;
const { Option } = Select;

const BusinessList = () => {
    const [userName, setUserName] = useState('');
    const [businesses, setBusinesses] = useState([]);
    const [visible, setVisible] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [mccAccounts, setMccAccounts] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [selectedMccAccountId, setSelectedMccAccountId] = useState(null);
    const [showAllAccounts, setShowAllAccounts] = useState(false);
    const [form] = Form.useForm();
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectOpen2, setSelectOpen2] = useState(false);
    const navigate = useNavigate();
    const initialRender = useRef(true);
    const [businessLimit, setBusinessLimit] = useState([]);
    const [loadingSubAccounts, setloadingSubAccounts] = useState(false);
    const [modalLoading, setModalLoading] = useState(true);
    const [selectedMccHasSubMcc, setSelectedMccHasSubMcc] = useState(false);  // 新的状态变量
    const [mccHierarchy, setMccHierarchy] = useState([]);  // 用来存储MCC账户的层级路径
    const [mccLevels, setMccLevels] = useState([{ mccAccounts: [], selectedMcc: null }]);  // 管理多层级的 MCC 状态
    const [dropdownVisibility, setDropdownVisibility] = useState([]); // 管理每个层级的下拉菜单显示状态
    const [loadingStates, setLoadingStates] = useState([]); // 管理每个层级的加载状态


    

    // Effect to retrieve user info from localStorage
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            const userData = localStorage.getItem('user');
            if (userData) {
                const user = JSON.parse(userData);
                setUserName(user.username); // Set user name from localStorage
                checkGoogleAdsAccountAuthorization();
                getBusinessLimit();
            }
        }
    }, []); 

    const getBusinessLimit = async()=>{
        const userId = JSON.parse(localStorage.getItem('user')).id
        console.log(userId)
        try{
            const { data:responseData} = await apiClient.post('/api/businesses/business-limitation', userId);
            console.log("limit response data: ", responseData)
            setBusinessLimit(responseData);
        }catch(error){
            console.log("Error getBusinessLimit ",error)
        }
    }



    const checkGoogleAdsAccountAuthorization = async () => {
        try {
            const response = await apiClient.get('/check-authorization');
            if(response.data){
                await Promise.all([fetchBusinesses(), fetchAccounts(), fetchMccAccounts()]);
            } else {
                Modal.warning({
                    title: 'Authorization Required',
                    content: 'You need to authorize your Google Ads account to create and view businesses. Please go to the binding page to authorize your account.',
                    onOk() {
                        navigate('/bind');
                    }
                });
            }
        } catch (error) {
            console.error('Failed to check authorization:', error);
        } finally {
            setModalLoading(false);
        }
    };
    
    const fetchBusinesses = async () => {
        const response = await apiClient.get('/api/businesses/all');
        setBusinesses(response.data);
        console.log('After fetchBusinesses localStorage:', localStorage);
      };

      const fetchAccounts = async () => {
        try {
            const response = await apiClient.get('/api/google-ads-accounts/allAccounts');
            setAccounts(response.data);
        } catch (error) {
            console.error('Failed to fetch accounts:', error);
        }
    };

    const fetchMccAccounts = async () => {
        try {
            const response = await apiClient.get('/api/google-ads-accounts/getAllMccAccount');
            setMccAccounts(response.data);
            // 更新第一层级的 MCC 账户
            setMccLevels([{ mccAccounts: response.data, selectedMcc: null }]);
        } catch (error) {
            console.error('Failed to fetch MCC accounts:', error);
        }
    };

    const fetchSubAccounts = async (mccAccountId, mccHierarchy, levelIndex) => {
        const newLoadingStates = [...loadingStates];
        newLoadingStates[levelIndex] = true;
        setLoadingStates(newLoadingStates);

        console.log("mccAccountId: ", mccAccountId)
        console.log("mccHierarchy: ", mccHierarchy)
        try {
            const requestData = {
                "mccAccountId": mccAccountId,
                "mccHierarchy": mccHierarchy
            }
            const response = await apiClient.post(`/api/google-ads-accounts/getSubAccountsFromMcc`, requestData);
            // 区分MCC账户和普通子账户
            const mccSubAccounts = response.data.filter(account => account.isManager);
            const normalSubAccounts = response.data.filter(account => !account.isManager);

            setSubAccounts(normalSubAccounts);  // 设置普通子账户
            console.log("subAccounts: ", normalSubAccounts);

            // 如果存在下一级MCC账户，设置为true，否则为false
            setSelectedMccHasSubMcc(mccSubAccounts.length > 0);
            console.log("selectedMccHasSubMcc: ", mccSubAccounts.length > 0)

            // 返回所有的MCC子账户
            return mccSubAccounts;

            // setSelectOpen2(true)
        } catch (error) {
            console.error('Failed to fetch sub accounts:', error);
            return [];
        }finally{
            // 加载完成，将当前层级的加载状态设置为 false
            const updatedLoadingStates = [...loadingStates];
            updatedLoadingStates[levelIndex] = false;
            setLoadingStates(updatedLoadingStates);     
        }
    };

    const handleCreate = () => {
        form.validateFields().then(async (values) => {
            // 使用 mccHierarchy 的第一个元素作为 closestMccAccountId
            const closestMccAccountId = mccHierarchy.length > 0 ? mccHierarchy[0] : '*';
            console.log("Closest MCC Account ID: ", closestMccAccountId);

            const requestData = {
                "name": values.name,
                "description": values.description,
                "currency": values.currency,
                "googleAdsMccAccountId": closestMccAccountId,
                "googleAdsAccountIds": values.googleAdsAccountIds
            };
            console.log('Values before sending:', requestData);

            try {
                await apiClient.post('/api/businesses/create', requestData);
                console.log(values);
                fetchBusinesses();
                form.resetFields();
                setVisible(false);
            } catch (error) {
                console.error("Error creating business:", error);
                message.error("Failed to create business. Please try again.");
            }
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };
    // Empty dependency array to run only on component mount


    // const handleSelectChange = (value) => {
    //     setSelectOpen(false); // Close the select dropdown after selection
    // };

    const handleSelectChange2 = (value) => {
        console.log("selectMccId: ", selectedMccAccountId)
        if (!selectedMccAccountId) {
            message.warning("Please select an MCC account before selecting sub accounts.");
            return;
        }
        setSelectOpen2(true); // still open the select dropdown after selection
    };

    const handleMccAccountChange = async(levelIndex, value) => {
        if(value === '*'){
            setShowAllAccounts(true);
            setloadingSubAccounts(true)
            setSubAccounts(accounts);
            setloadingSubAccounts(false)
            setSelectOpen2(true)
            // 重置 mccHierarchy
            setMccHierarchy([]);
            // 重置 mccLevels，只保留第一级
            setMccLevels([{ mccAccounts: mccAccounts, selectedMcc: '*' }]);
        } else {
            setShowAllAccounts(false);
            // 更新 mccHierarchy，只保留到当前级别
            const updatedHierarchy = mccHierarchy.slice(0, levelIndex);
            updatedHierarchy[levelIndex] = value;
            setMccHierarchy(updatedHierarchy);
            console.log("Updated MccHierarchy: ", updatedHierarchy);

            // 更新 mccLevels，只保留到当前级别
            const newMccLevels = mccLevels.slice(0, levelIndex + 1);
            newMccLevels[levelIndex].selectedMcc = value;

            try {
                // 使用更新后的 hierarchy 调用 API
                const subMccAccounts = await fetchSubAccounts(value, updatedHierarchy, levelIndex);

                if (subMccAccounts.length > 0) {
                    // 只添加下一级，移除之后的所有级别
                    newMccLevels[levelIndex + 1] = { mccAccounts: subMccAccounts, selectedMcc: null };
                    setMccLevels(newMccLevels);
                } else {
                    // 如果没有子 MCC 账户，说明已经到达最后一级，应该显示子账户
                    setMccLevels(newMccLevels);
                    setSelectOpen2(true);  // 打开子账户选择下拉框
                }

                updateDropdownVisibility(newMccLevels);

                const newVisibility = [...dropdownVisibility];
                newVisibility[levelIndex] = false;
                // 清除当前级别之后的所有可见性状态
                newVisibility.splice(levelIndex + 1);
                setDropdownVisibility(newVisibility);

                setSelectedMccAccountId(value);

                // 无论是否有子 MCC 账户，都清除子账户选择
                form.setFieldsValue({ googleAdsAccountIds: [] });
            } catch(error) {
                console.log("Error handleMccAccountChange ", error);
            }
        }

        // 清除当前级别之后的所有选择
        const fieldsToReset = mccLevels.slice(levelIndex + 1).map((_, idx) => `googleAdsMccAccountIdLevel${levelIndex + idx + 1}`);
        fieldsToReset.push('googleAdsAccountIds');  // 添加子账户字段到重置列表
        form.resetFields(fieldsToReset);
    };


    const handleRowClick = (record) => {
        navigate(`/business/${record.id}`);
    };

    // const handleOpenModal = async () => {
    //     form.resetFields(); // 清除之前的数据
    //     await fetchAccounts(); // 重新获取API的数据
    //     await fetchMccAccounts();
    //     setVisible(true);
    // };

    // const handleCloseModal = () => {
    //     setVisible(false);
    // };

    // Define the columns for the table
    const columns = [
        {
            title: 'Business ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Business Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Google Ads Account IDs',
            dataIndex: 'googleAdsAccountIds',
            key: 'googleAdsAccountIds',
            // render: (ids) => Array.isArray(ids) ? ids.join(', ') : '',
            render: (ids) => {
                if (!Array.isArray(ids)) return '';
    
                const maxToShow = 2;
                const remainingCount = ids.length - maxToShow;
    
                return (
                    <>
                        {ids.slice(0, maxToShow).join(', ')}
                        {remainingCount > 0 && `... +${remainingCount}`}
                    </>
                );
            },
        },
    ];

    const currencies = [
        { code: 'EUR', name: 'Euro' },
        { code: 'USD', name: 'United States Dollar' },
        { code: 'DKK', name: 'Danish Krone' },
        { code: 'GBP', name: 'British Pound' },
        { code: 'HKD', name: 'Hong Kong Dollar' },
        { code: 'NOK', name: 'Norwegian Krone' },
        { code: 'SEK', name: 'Swedish Krona' },
        { code: 'CNY', name: 'Chinese Yuan' },
        { code: 'CAD', name: 'Canadian Dollar' },
        { code: 'AUD', name: 'Australian Dollar' },
        { code: 'CHF', name: 'Swiss Franc' },
        { code: 'JPY', name: 'Japanese Yen' },
        { code: 'SGD', name: 'Singapore Dollar'},
        { code: 'NZD', name: 'New Zealand Dollar'},
        { code: 'KRW', name: 'South Korean Won'},
        { code: 'INR', name: 'Indian Rupee'},
        { code: 'MXN', name: 'Mexican Peso'},
        { code: 'ZAR', name: 'South African Rand'},
        { code: 'BRL', name: 'Brazilian Real'},
        { code: 'THB', name: 'Thai Baht'},
        { code: 'ILS', name: 'Israeli New Shekel'},
        { code: 'CZK', name: 'Czech Koruna'},
        { code: 'TRY', name: 'Turkish Lira'},
        { code: 'HUF', name: 'Hungarian Forint'}

    ];

    const handleCreateOnClick = () => {
        const currentCount = businesses.length;
        console.log("Business Count: ", currentCount);

        if(currentCount >= businessLimit){
            // 如果达到限制，弹出提示窗口
            Modal.warning({
                title: 'Limit Reached',
                content: 'You have reached the maximum limit of businesses. Please upgrade your plan or contact us for more details.',
            });
        } else {
            setVisible(true);
        }
    }

    // 控制子账户下拉框的显示
    // const handleDropdownVisibleChange = (open) => {
    //     if (!selectedMccAccountId) {
    //         // message.warning("Please select an MCC account before selecting sub accounts. \nIf you don't have an MCC account, please select 'All Accounts' in the MCC Account menu.");
    //         // message.warning(
    //         //     'Please select an MCC account before selecting sub accounts.\n' +
    //         //     'If you don\'t have an MCC account, please select "All Accounts" in the MCC Account menu.',
    //         // );
    //         showWarningNotification()
    //         setSelectOpen(false); // 阻止下拉框的打开
    //     } else {
    //         setSelectOpen2(open); // 根据用户操作打开或关闭下拉框
    //     }
    // };

    const handleDropdownVisibleChange = (levelIndex, open) => {
        // const newVisibility = [...dropdownVisibility];
        // newVisibility[levelIndex] = open; // 只更新当前层级的显示状态
        // setDropdownVisibility(newVisibility);
        const newVisibility = [...dropdownVisibility];
        newVisibility[levelIndex] = open;
        setDropdownVisibility(newVisibility);
    };

    const updateDropdownVisibility = (newLevels) => {
        // 同步 dropdownVisibility 数组与 mccLevels 的长度和状态
        const newVisibility = newLevels.map((_, index) => dropdownVisibility[index] || false);
        setDropdownVisibility(newVisibility);
    };

    // 显示详细提示
    const showWarningNotification = () => {
        notification.warning({
            message: 'Warning',
            description: (
                <div>
                    Please select an MCC account before selecting sub accounts.<br />
                    If you don't have an MCC account, please select 'All Accounts' in the MCC Account menu.
                </div>
            ),
            placement: 'topRight', // 这里设置 placement 为 topRight 以方便样式覆盖，
            duration: 5
        });
    };

    const handleCancel = () => {
        form.resetFields(); // 清空表单内容
        setSelectedMccAccountId(null)

        // setMccAccounts([]);  // 重置MCC账户列表
        // setSubAccounts([]);  // 重置子账户列表
        setSelectedMccHasSubMcc(false);  // 重置下一层MCC状态

        setVisible(false); // 关闭窗口
    };

    const contentStyle = {
        padding: 50,
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
      };
    const content = <div style={contentStyle} />;

    return(
        <Content style={{ margin: 0, padding: 24, background: '#fff', flex: 1 }}>
        <Typography.Title level={2}>Business Dashboard</Typography.Title>
        <Typography.Text>Hello, {userName}!</Typography.Text>

        {/* <Button type="primary" onClick={() => setVisible(true)} style={{ marginBottom: 20 ,marginLeft: 1200}}>
            + Create
        </Button> */}
        <Row justify="end" style={{ marginBottom: 20 }}>
            <Col>
                <Button type="primary" onClick={handleCreateOnClick}>
                    + Create
                </Button>
            </Col>
        </Row>

        <StyledCard>
            <Table
            columns={columns}
            dataSource={businesses}
            rowKey="id"
            pagination={false}
            style={{ marginTop: 20 }}
            scroll={{ x: '100%' }}
            onRow={(record) =>({
                onClick: () => handleRowClick(record),
                style: { cursor: 'pointer' }
            })}
            />
        </StyledCard>
        <Modal
                title="Create New Business"
                visible={visible}
                // onCancel={() => setVisible(false)}
                onCancel={handleCancel}
                onOk={handleCreate}
            >
                {modalLoading ? (
                    <Spin tip="Loading data...">
                        <div style={{ padding: '50px' }} />
                    </Spin>
                ) : (
                    <Form form={form} layout="vertical">
                        <Form.Item
                            name="name"
                            label="Business Name"
                            rules={[{ required: true, message: 'Please input the business name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[{ required: true, message: 'Please input the description!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="currency"
                            label="Currency"
                            rules={[{ required: true, message: 'Please select a currency!' }]}
                        >
                            <Select placeholder="Please Select Currency">
                                {/* <Option value="EUR">EUR</Option>
                                <Option value="DKK">DKK</Option>
                                <Option value="CNY">CNY</Option> */}  
                                {currencies.map(currency => (
                                    <Option key={currency.code} value={currency.code}>
                                        {currency.code} - {currency.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        
                        {mccLevels.map((level, index) => (
                        <Form.Item
                            key={index}
                            name={`googleAdsMccAccountIdLevel${index}`}
                            label={`MCC Level ${index + 1}`}
                            rules={[{ required: true, message: 'Please select an account!' }]}
                        >
                            {loadingStates[index] ? (
                                <Spin tip="Loading sub accounts ... " size="large"> {content}</Spin>
                            ) : (
                                <Select 
                                    placeholder={`Select Google Ads Mcc Account for Level ${index + 1}`}
                                    open={dropdownVisibility[index]}
                                    onDropdownVisibleChange={(open) => handleDropdownVisibleChange(index, open)}
                                    value={level.selectedMcc}
                                    onChange={(value) => handleMccAccountChange(index, value)}
                                >
                                    <Option value="*">All Accounts - If No MCC Account</Option>
                                    {level.mccAccounts.map(account => (
                                        <Option key={account.accountId} value={account.accountId}>
                                            {`${account.accountId} - ${account.username && account.username.trim() !== '' ? account.username : 'Not defined'} - ${account.isManager ? 'Manager' : 'Non-Manager'}`}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        ))}
                        {/* 动态生成的下拉框，如果存在新的MCC账户 */}
                        {/* {selectedMccHasSubMcc && (
                            <Form.Item
                                name="nextMccAccountId"
                                label="Next Level MCC Accounts"
                                rules={[{ required: true, message: '选择下一级MCC账户！' }]}
                            >
                                <Select
                                    placeholder="选择下一级 MCC 账户"
                                    onChange={handleMccAccountChange}
                                >
                                    {mccAccounts.map(account => (
                                        <Option key={account.accountId} value={account.accountId}>
                                            {`${account.accountId} - ${account.username && account.username.trim() !== '' ? account.username : '未定义'} - MCC`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )} */}

                        <Form.Item
                            name="googleAdsAccountIds"
                            label="Google Ads Sub Accounts"
                            rules={[{ required: true, message: 'Please select an sub account!' }]}
                        >
                            {/* <Select mode="multiple" placeholder="选择 Google Ads 账户">
                                {accounts.map(account => (
                                    <Option key={account.id} value={account.id}>{account.name}</Option>
                                ))}
                            </Select> */}
                            <Select 
                                mode="multiple" //启动下拉菜单的多选
                                placeholder="Select Google Ads Sub Account"
                                open={selectOpen2}
                                onDropdownVisibleChange={(open) => setSelectOpen2(open)}
                                // onDropdownVisibleChange={handleDropdownVisibleChange}
                                value={form.getFieldValue('googleAdsAccountIds') || []}
                                onChange={
                                    (value)=>{
                                        if(value.includes('select all')){
                                        //select all sub accounts
                                        const allValues = (showAllAccounts ? accounts : subAccounts).map(account => account.accountId);
                                        // form.setFieldsValue({ googleAdsAccountIds: allValues });
                                        // setSelectOpen2(false); // 关闭下拉菜单
                                            // 检查当前是否已经全部选择
                                            if (value.length - 1 === allValues.length) {
                                                // 如果全部选中了，则取消所有选择
                                                form.setFieldsValue({ googleAdsAccountIds: [] });
                                            } else {
                                                // 如果未全部选中，则选择所有选项
                                                form.setFieldsValue({ googleAdsAccountIds: allValues });
                                            }
                                        }else{
                                            handleSelectChange2(value)
                                        }
                                    }
                                }
                                >
                                {/* <Option key="all" value="select all">
                                    Select All
                                </Option> */}
                                    {/* 只有在 subAccounts 列表中有数据时才显示 "Select All" 选项 */}
                                {(showAllAccounts ? accounts : subAccounts).length > 0 && (
                                    <Option key="all" value="select all">
                                        Select All
                                    </Option>
                                )}
                                {(showAllAccounts ? accounts : subAccounts).map(account => (
                                    <Option key={account.accountId} value={account.accountId}>
                                        {`${account.accountId} - ${account.username && account.username.trim() !== '' ? account.username : 'Not defined'}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
      </Content>
    )
};

export default BusinessList;
