import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import apiClient from '../../utils/apiClient';
import{Skeleton, Card, notification, Empty} from 'antd';
import { LineChartOutlined ,DotChartOutlined} from '@ant-design/icons'; // 使用图表图标
import ReactEcharts from 'echarts-for-react'; // 使用标准的 ECharts for React 组件
import * as echarts from 'echarts'; // 导入 ECharts 核心库
import StyledCard from '../../components/StyledCard';


  const generateOptions = ({ title, unit, legends, xAxis, series, colors }) => {
    return {
      grid: {
        show: false,
        containLabel: false,
        top: '10%',
        left: '7%',
        right: '1%',
        bottom: '0%',
        height: '75%',
      },
      legend: {
        top: '0%',
        data: legends,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
        formatter: (params) => {
          let dom = `<div style="text-align: left">
                       <div style="text-align: center;font-weight: 600">${params[0].axisValue}</div>
                       <div style="text-align: center;font-weight: 600">${title}</div>`;
          let item, value;
  
          params.forEach((item, i) => {
            let value = `${Math.round(item.data).toLocaleString()} ${unit}`; // 去掉小数并增加千位分割符
            dom += `<div style="color: #fff">
                        <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${colors[i].end};"></span>
                        <span style="text-align:left; color: rgba(0, 0, 0, 0.85);">${item.seriesName}:</span> 
                        <span style="color: rgba(0, 0, 0, 0.85);">${value}</span>
                      </div>`;
          });
          return dom + "</div>";
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#999',
          formatter: (value) => {
            if (value >= 1000) {
                return `${(value / 1000).toLocaleString()}K`; // 将纵坐标单位转换为 1K
              }
              return value.toLocaleString();
          },
        },
        splitNumber: 5, // 控制y轴的分割段数
      },
      series: series,
    };
  };

const RevenueChart = ({ id , startDate, endDate, isDefault, currency}) => {
    // const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [xAxis, setXAxis] = useState([]);
    const [legends, setLegends] = useState([]);
    const [series, setSeries] = useState([]);
    const [currLifeCycle, setCurrLifeCycle] = useState(1);
    const [colors, setColors] = useState([
      { start: "#fffee6", end: "#8B5EF5" },
    ]);
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        const fetchRevenueData = async () => {
            try {
                const userId = JSON.parse(localStorage.getItem('user')).id
                console.log("business id:", id)
                const businessData = {
                    userId: userId,
                    businessId: id,
                    startDate: startDate,  // 使用传递进来的 startDate
                    endDate: endDate       // 使用传递进来的 endDate
                };
                const response = await apiClient.post(`/api/google-ads/revenue/30days`, businessData);
                const data = response.data;
                console.log("revenue data: " ,data)

                const labels = data.map(item => item.date);
                const revenues = data.map(item => item.revenue);

                const _series = [{
                    name: 'Daily Revenue',
                    type: 'line',
                    data: revenues,
                    lineStyle: { width: 3 },
                    itemStyle: { color: colors[0].end },
                    smooth: true,
                    areaStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: colors[0].end },
                        { offset: 1, color: colors[0].start },
                      ]),
                    },
                }];
                setXAxis(labels);
                setLegends([{ name: 'Daily Revenue', itemStyle: { color: colors[0].end }, lineStyle: { color: colors[0].end } }]);
                setSeries(_series);
                setLoading(false);

                console.log("chartData lable: ",chartData.label)
            } catch (error) {
                setLoading(false);
                console.error('Error fetching revenue data:', error);
            }
        };

        fetchRevenueData();
    }, [id, startDate, endDate]);


    if (loading) {
        return <Skeleton />;
      } else {
        return (
            <StyledCard>
            <p>Revenue from {startDate} to {endDate} {isDefault && '(Default Last 30 Days)'}</p>
            <p>Currency: {currency}</p>
            <ReactEcharts
              echarts={echarts}
              option={generateOptions({
                title: '',
                unit: currency,
                legends,
                xAxis,
                series,
                latest: currLifeCycle,
                colors,
              })}
              style={{ width: '100%', height: '460px' }}
            //   theme={theme.theme}
            />
          </StyledCard>
        )
      }
};

export default RevenueChart;