import React, { useState, useEffect ,useRef, useMemo} from 'react';
import { Layout, Card, Table, Typography, Space, Form ,Modal, Select, Input, Button, 
    InputNumber, message, Radio, Row, Col, Statistic,Switch, Tooltip, DatePicker, Spin, Alert, Skeleton, Dropdown, Menu, Badge} from 'antd';
import{FundViewOutlined, DollarOutlined, ShoppingCartOutlined, AimOutlined, GooglePlusOutlined,
    YoutubeOutlined, LinkedinOutlined, FacebookOutlined, TikTokOutlined, CalculatorOutlined, BankOutlined, DownOutlined, BellOutlined,
    IdcardOutlined, ShopOutlined, FileTextOutlined, CalendarOutlined} from "@ant-design/icons"
import { useParams } from 'react-router-dom';
import apiClient from '../../utils/apiClient';
import RevenueChart from '../Charts/RevenueChart'
import moment from 'moment';
import styled from 'styled-components';
import StyledCard from '../../components/StyledCard';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

// 添加全局样式
const GlobalStyle = styled.div`
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #333;
  line-height: 1.6;
`;

// 自定义标题样式
const StyledTitle = styled(Title)`
  font-weight: 600;
  color: #1890ff;
`;

// 自定义文本样式
const StyledText = styled(Text)`
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
`;

// 自定义强调文本样式
const BoldText = styled.span`
  font-weight: 600;
  color: #1890ff;
`;

// useErrorOnce Hook definition
const useErrorOnce = () => {
    const lastErrorTime = useRef(0);

    const showErrorOnce = (messageText, duration = 3000) => {
        const currentTime = Date.now();
        if (currentTime - lastErrorTime.current > duration) {
            lastErrorTime.current = currentTime;
            message.error(messageText);
        }
    };

    return showErrorOnce;
};

const InfoCard = styled(Card)`
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  margin-bottom: 24px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 16px;
  color: #333;
`;

const InfoIcon = styled.span`
  font-size: 32px;
  margin-right: 16px;
  color: #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InfoLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #8c8c8c;
  line-height: 1;
`;

const InfoValue = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #262626;
  margin-top: 4px;
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #f7f9fc;
    color: #2c3e50;
    font-weight: 600;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    padding: 16px;
  }

  .total-row {
    background-color: #f7f9fc;
    font-weight: 600;
  }

  .total-row td {
    border-top: 2px solid #e8e8e8 !important;
    color: #2c3e50;
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #1890ff;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid #1890ff;
  display: inline-block;
`;

const StyledDashboardCard = styled(Card)`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  margin-bottom: 24px;
`;

const WhiteLayout = styled(Layout)`
  background: white;
`;

const BusinessDetail = () => {
    const { id } = useParams();
    const [business, setBusiness] = useState({});
    const [overallPerformance, setOverallPerformance] = useState({});
    const [accountsPerformance, setAccountsPerformance] = useState([]);
    const [convertAccountsPerformance, setConvertAccountsPerformance] = useState([]); // 转化汇率后的数据
    const [targetAchievement, setTargetAchievement] = useState([]);
    const [allAccountIds, setAllAccountIds] = useState([]);
    const [totalProfit, setTotalProfit] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [targets, setTargets] = useState({});
    const [isTargetModalVisible, setIsTargetModalVisible] = useState(false);
    const [isConfigured, setIsConfigured] = useState(false);
    const [form] = Form.useForm();
    const [targetForm] = Form.useForm();
    const [exchangeRates, setExchangeRates] = useState({});
    const [targetValue, setTargetValue] = useState(''); // 用于存储输入框的值
    const [showConvertedData, setShowConvertedData] = useState(true); // 状态来控制显示数据
    const [monthlyData, setMonthlyData] = useState([]); //用来储存每个月的数据
    const [selectedYear, setSelectedYear] = useState(moment().year()); // 选择年份，默认值为今年
    const [isBusinessTargets, setIsBusinessTargets] = useState([]); //查询是已经set business targets

    //overall performance
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //accounts performance
    const [accountsPerformanceLoading, setAccountsPerformanceLoading] = useState(true);

    //monthly data loading
    const [monthlyDataLoading, setMonthlyDataLoading] = useState(false);

    //business config loading
    const [businessConfigLoading, setBusinessConfigLoading] = useState(true);

    //date setting consts
    const [defaultDateRange, setDefaultDateRange] = useState([
        moment().subtract(30, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day')
    ]);

    const [topDateRange, setTopDateRange] = useState([
        moment().subtract(30, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day')
    ]);

    const [dateRangeDefault, setDateRangeDefault] = useState(true); // 用于判断是否使用默认日期

    // 年份下拉菜单项生成，逆序排列
    const years = [];
    for (let year = moment().year(); year >= 2000; year--) {
        years.push(
            <Menu.Item key={year} onClick={() => handleYearChange(year)}>
                {year}
            </Menu.Item>
        );
    }
    
            // 处理年份改变
    const handleYearChange = (year) => {
        setSelectedYear(year);
        setMonthlyData([]); // 清空表格数据
    };



    // Use the custom hook
    const showErrorOnce = useErrorOnce();

    // 添加一个用于检查是否已经准备好加载 RevenueChart 的状态
    const [isReadyToLoadChart, setIsReadyToLoadChart] = useState(false);

    // 添加一个新的状态来跟踪未读消息数量
    const [unreadMessages, setUnreadMessages] = useState(5);

    // 添加一个处理点击通知图标的函数
    const handleNotificationClick = () => {
        message.info('查看通知');
        // 这里可以添加打开通知列表或跳转到通知页面的逻辑
        setUnreadMessages(0); // 将未读消息数量设置为0
    };

    useEffect(() => {
        setIsConfigured(false);
        console.log("defaultDateRange: ",defaultDateRange);
        console.log("defaultDateRange[0]: ",defaultDateRange[0].format('YYYY-MM-DD'));
        const checkConfiguration = async () => {
          try {
              const userId = JSON.parse(localStorage.getItem('user')).id
              const businessData = {
                userId: userId,
                businessId: id
            };
            const { data:is_config_responseData} = await apiClient.post(`/api/businesses/is-configured`, businessData);
              console.log("isConfig: ", is_config_responseData)
              if (!is_config_responseData) {
                  setIsModalVisible(true);
              } else {
                  setIsConfigured(true);
              }
            } catch (error) {
                console.error('Failed to check configuration:', error);
                // showErrorOnce('Failed to check configuration');
            }
        };

        const checkBusinessTargetsSet = async()=>{
            try{
                const userId = JSON.parse(localStorage.getItem('user')).id
                const businessData = {
                  userId: userId,
                  businessId: id
                };
                const { data:is_business_targets} = await apiClient.post(`/api/businesses/is-business-targets`, businessData);
                console.log("is_business_targets: ",is_business_targets);
                if(is_business_targets){
                    const userId = JSON.parse(localStorage.getItem('user')).id
                    const targetsData = {
                        userId:userId,
                        businessId: id
                    }
                    const { data:get_monthly_data} = await apiClient.post(`/api/businesses/monthly-data`, targetsData);
                    console.log("monthly_data: ", get_monthly_data);
                    const data = get_monthly_data;
                    const formattedData = [
                        { ...data.sales, key: '1' },
                        { ...data.targets, key: '2' },
                        { ...data.attainment, key: '3' }
                    ];
                    console.log("formattedData: ",formattedData)
                    setMonthlyData(formattedData)
                }

            }catch(error){
                console.log("checkBusinessTargetsSet:" , error)
            }
        }

        const setDefaultDateRangeSet = async()=>{
            try{
                const startDate = defaultDateRange[0].format('YYYY-MM-DD');
                const endDate = defaultDateRange[1].format('YYYY-MM-DD');
                console.log("startDate&endDate", startDate," ",endDate);
                const userId = JSON.parse(localStorage.getItem('user')).id
                const businessData = {
                  userId: userId,
                  businessId: id,
                  startDate: startDate,
                  endDate: endDate
                };
                const { data:is_default_DateRangeSet} = await apiClient.post(`/business/date-range/save-date-range`, businessData);
                console.log("is_default_DateRangeSet: ", is_default_DateRangeSet)
                if(is_default_DateRangeSet){
                    console.log('Default date range saved successfully!')
                }
            }catch(error){
                console.log('Failed to save default date range. error: ', error)
                // 如果后端返回的内容表示失败
                showErrorOnce('Failed to save default date range.');
            }
        }

        const getAccountIdsForBusiness = async () => {
            try{
                const userId = JSON.parse(localStorage.getItem('user')).id
                const getAccountsData = {
                    userId:userId,
                    businessId: id
                }
                const { data:getAccountIdsForBusiness_response } = await apiClient.post(`/api/businesses/getAccountIdsForBusiness`, getAccountsData);
                console.log("all account ids: ", getAccountIdsForBusiness_response)
                setAllAccountIds(getAccountIdsForBusiness_response)
                setBusinessConfigLoading(false);
            }catch(error){
                console.error('Failed to get account ids for business:', error);
                showErrorOnce('Failed to get account ids');
            }
        }


        console.log("businessId ",id);
        const fetchBusinessDetails = async () => {
          const data = await apiClient.get(`/api/businesses/${id}`);
          console.log("business data:",data)
          setBusiness(data.data);
        //   if (data.data.currency) {
        //     fetchExchangeRates(data.data.currency); // 确保传递了有效的 currency 参数
        //   } else {
        //     console.error('Currency is not defined for the business');
        //   }
        };


    const fetchInitialDateRange = async () => {
        try {
            const userId = JSON.parse(localStorage.getItem('user')).id;
            const businessData = {
                userId: userId,
                businessId: id
            };

            // 获取用户设置的日期范围
            const { data: savedDateRange } = await apiClient.post(`/business/date-range/get-date-range`, businessData);
            
            console.log("get date range: ", savedDateRange.startDate, "   ," , savedDateRange.endDate)
            if (savedDateRange && savedDateRange.startDate && savedDateRange.endDate) {
                const savedStartDate = moment(savedDateRange.startDate);
                const savedEndDate = moment(savedDateRange.endDate);
    
                // 查获取的日期范围是否与默认的 `defaultDateRange` 相同
                if (
                    savedStartDate.isSame(defaultDateRange[0], 'day') &&
                    savedEndDate.isSame(defaultDateRange[1], 'day')
                ) {
                    setDateRangeDefault(true);
                } else {

                    // 如果有保的日期范围，则使用该日期范围
                    setTopDateRange([moment(savedDateRange.startDate), moment(savedDateRange.endDate)]);
                    setDateRangeDefault(false);
                }
            } else {
                // 如果没有保存的日期范围，则使用默认期范围
                setTopDateRange(defaultDateRange);
                setDateRangeDefault(true);
            }
        } catch (error) {
            console.error('Failed to fetch initial date range:', error);
            setTopDateRange(defaultDateRange);
            setDateRangeDefault(true);
            // 保存默认日期范围到数据库
            await setDefaultDateRangeSet();
        } finally {
            await fetchBusinessDetails();
            await checkConfiguration();
            await getAccountIdsForBusiness();
            await checkBusinessTargetsSet();
            // 在所有异步操作成后设置为true，允许加载图
            setIsReadyToLoadChart(true);
        }
    };
    
    fetchInitialDateRange();



    }, [id]);

    useEffect(() => {
        if (isConfigured) {
            const fetchPerformanceData = async () => {
                // 首先获取账户的表现数据
                await fetchAccountsPerformance();
                // 确保 fetchAccountsPerformance 完成后再执行 fetchOverallPerformance
                // await fetchOverallPerformance();
            };
    
            fetchPerformanceData();
        }
        // fetchAccountsPerformance()
    }, [isConfigured]);

    const fetchOverallPerformance = async () => {
        setLoading(true);
        setError(null); // 重置错误状态
        try{
            console.log("start to get overallPerformanceResponse!!");
            const userId = JSON.parse(localStorage.getItem('user')).id;
            const businessData = {
              userId: userId,
              businessId: id,
              aimCurrency: business.currency
            };
            console.log("overall businessData:", businessData)
            if (!userId || !business.currency || !id) {
                throw new Error('Missing userId or businessCurrency or businessid');
            }
            const { data:overallPerformanceResponse } = await apiClient.post(`/api/businesses/getOverallPerformance`,businessData);
            console.log("overallPerformanceResponse: ",overallPerformanceResponse);
            setOverallPerformance(overallPerformanceResponse);
        }catch(error){
            console.log("Over all performance error: ", error);
            // showErrorOnce("Failed to fetch business over all performance data!");
            setError('Failed to fetch business overall performance data.');
        }finally{
            setLoading(false);
        }
    };

    const fetchAccountsPerformance = async () => {
        setAccountsPerformanceLoading(true)
        try{
            const userId = JSON.parse(localStorage.getItem('user')).id
            const businessData = {
                userId: userId,
                businessId: id
            };
            console.log("businessData for post performance: ", businessData)

            const { data:performanceResponse } = await apiClient.post(`/api/businesses/accounts-performance`, businessData);
            console.log("performance data:", performanceResponse);

            setAccountsPerformance(performanceResponse);
            // const accountsWithTotal = enhanceWithTotalRow(performanceResponse);
            // setAccountsPerformance(accountsWithTotal);

                    // 假 performanceResponse 是 List<List<Map<String, AccountPerformance>>>
            const originalPerformance = performanceResponse.map(list => list[0].original);
            const convertedPerformance = performanceResponse.map(list => list[1].converted);

            // // 转换数据
            // const convertedData = performanceResponse.map(account => ({
            //     ...account,
            //     sales: convertValue(account.sales, account.currencyCode),
            //     investment: convertValue(account.investment, account.currencyCode),
            //     profit: convertValue(account.profit, account.currencyCode),
            // }));
            // setConvertAccountsPerformance(convertedData);
            setAccountsPerformance(originalPerformance);  // 设置原始货币据
            setConvertAccountsPerformance(convertedPerformance);  // 设置转换后的币数据
            fetchOverallPerformance()
        }catch(error){
            console.error('Failed to fetch accounts performance:', error.response?.data || error.message)
            // showErrorOnce('Failed to fetch accounts performance');
        }finally{
            setAccountsPerformanceLoading(false)
        }
    };

    const convertValue = (value, currencyCode) => {
        const exchangeRate = exchangeRates[currencyCode.toLowerCase()] || 1;
        return (value / exchangeRate).toFixed(2);
    };

    function DashboardCard({title, value, icon,color,showCurrency = true}){
        console.log("DashboardCard value: ", value)

        //     // 处理非数字值
        // const numericValue = typeof value === 'number' ? value : 0;

        // 使用 Number 转换 value 以确保其为数字类型
        const numericValue = Number(value);
        // const numericValue = isNaN(Number(value)) ? 0 : Number(value);
            // 首先确保 value 是一个数字，如果不是则将其转换为 0
        // const numericValue = typeof value === 'number' && !isNaN(value) ? value : 0;

        // 检查转换后 numericValue 是否为有效的数字
        if (isNaN(numericValue)) {
            console.error("Invalid value passed to DashboardCard:", value);
            return null; // 或者可以返回一个默认的组件内容
        }

        

            // 根据是否显示货决定显示的数位数
        const formattedValue = showCurrency 
        ? Math.floor(numericValue).toLocaleString()
        : value.toFixed(1).toLocaleString();

        const displayValue = showCurrency ? `${business.currency} ${formattedValue}` : formattedValue;
        return(
            <Card style={{
                background:{color},
                textAlign:'center'
                }} className="DashboardCard" hoverable="true">
                <Space direction="hrizontal">
                    <Statistic 
                        title={
                            <Space>
                                {icon}
                                {title}
                            </Space>
                        } 
                        value={displayValue}
                        valueStyle={{ color: '#3f8600' }}
                    />
                </Space>
             </Card>
        );
    }

    const calculateTotalProfit = (accounts) => {
        // 计算每个账户的利润转换后的值
        const accountsWithConvertedProfits = accounts.map(account => ({
            ...account,
            convertedProfit: convertProfit(account.profit, account.currencyCode)
        }));

        // const totalProfit = accounts.reduce((acc, account) => acc + parseFloat(account.profit || 0), 0);
        const totalProfit = accountsWithConvertedProfits.reduce((acc, account) => {
            const profit = parseFloat(account.convertedProfit) || 0; // 确保 profit 是数字
            return acc + profit;
        }, 0);
        return totalProfit.toFixed(2); // 格式化为两位小数，根据需要调整
    };

    const calculateConvertTotalProfit = () => {
        if (!convertAccountsPerformance || !Array.isArray(convertAccountsPerformance)) {
            return '0';
        }

        const total = convertAccountsPerformance.reduce((total, account) => {
            if (!account || account.profit === undefined || account.profit === null) {
                return total;
            }
            return total + parseFloat(account.profit || 0);
        }, 0);

        // 使用 Math.floor() 去掉小数部分，然后使用 toLocaleString() 添加千分位
        const roundedTotal = Math.floor(total);
        const formattedTotal = roundedTotal.toLocaleString();
        setTotalProfit(formattedTotal);
        return formattedTotal;
    };

    const convertProfit = (profit, currencyCode) => {
      const exchangeRate = exchangeRates[currencyCode.toLowerCase()] || 1;
      return (profit / exchangeRate).toFixed(2);
  };
    

    const columns = [
        {
            title: <div style={{ textAlign: 'center' }}>Account ID</div>,
            dataIndex: 'accountId',
            key: 'accountId',
            align: 'center'  // 改为居中对齐
        },
        {
            title: <div style={{ textAlign: 'center' }}>Account Name</div>,
            dataIndex: 'accountName',
            key: 'accountName',
            align: 'center',
        },
        {
            title: <div style={{ textAlign: 'center' }}>ROAS</div>,
            dataIndex: 'roas',
            key: 'roas',
            align: 'center',  // 改为居中对齐
            render: (text) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : value.toFixed(2);
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Sales</div>,
            dataIndex: 'sales',
            key: 'sales',
            align: 'right',
            render: (text, record) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : (
                    <span>
                        {Math.floor(value).toLocaleString()}{' '}
                        <span style={{ color: '#0050b3' }}>{record.currencyCode}</span>
                    </span>
                );
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Investment</div>,
            dataIndex: 'investment',
            key: 'investment',
            align: 'right',
            render: (text, record) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : (
                    <span>
                        {Math.floor(value).toLocaleString()}{' '}
                        <span style={{ color: '#0050b3' }}>{record.currencyCode}</span>
                    </span>
                );
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Profit</div>,
            dataIndex: 'profit',
            key: 'profit',
            align: 'right',
            render: (text, record) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : (
                    <span>
                        {Math.floor(value).toLocaleString()}{' '}
                        <span style={{ color: '#0050b3' }}>{record.currencyCode}</span>
                    </span>
                );
            }
        },
    ];

    const convert_performance_columns = [
        {
            title: <div style={{ textAlign: 'center' }}>Account ID</div>,
            dataIndex: 'accountId',
            key: 'accountId',
            align: 'center'  // 改为居中对齐
        },
        {
            title: <div style={{ textAlign: 'center' }}>Account Name</div>,
            dataIndex: 'accountName',
            key: 'accountName',
            align: 'center'
        },
        {
            title: <div style={{ textAlign: 'center' }}>ROAS</div>,
            dataIndex: 'roas',
            key: 'roas',
            align: 'center',  // 改为居中对齐
            render: (text) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : value.toFixed(2);
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Sales ({business.currency})</div>,
            dataIndex: 'sales',
            key: 'sales',
            align: 'right',
            render: (text) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : Math.floor(value).toLocaleString();
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Investment ({business.currency})</div>,
            dataIndex: 'investment',
            key: 'investment',
            align: 'right',
            render: (text, record) => {
                if (record.key === 'total') {
                    return 'Total Profit';
                } else {
                    const value = parseFloat(text);
                    return isNaN(value) ? '' : Math.floor(value).toLocaleString();
                }
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Profit ({business.currency})</div>,
            dataIndex: 'profit',
            key: 'profit',
            align: 'right',
            render: (text, record) => {
                if (record.key === 'total') {
                    return (
                        <span>
                            {text}{' '}
                            <span style={{ color: '#0050b3' }}>{business.currency}</span>
                        </span>
                    );
                } else {
                    const value = parseFloat(text);
                    return isNaN(value) ? '' : Math.floor(value).toLocaleString();
                }
            }
        }
    ];

    const targetColumns = [
        {
            title: `Sales Target`,
            dataIndex: 'salesTarget',
            key: 'salesTarget',
            render: (text) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : Math.floor(value).toLocaleString();
            }
        },
        {
            title: `Sales YTD`,
            dataIndex: 'salesYtd',
            key: 'salesYtd',
            render: (text) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : Math.floor(value).toLocaleString();
            }
        },
        {
            title: `Target to Close`,
            dataIndex: 'targetToClose',
            key: 'targetToClose',
            render: (text) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : Math.floor(value).toLocaleString();
            }
        },
        {
            title: `Target Attainment`,
            dataIndex: 'targetAttainment',
            key: 'targetAttainment',
            render: (text) => {
                const value = parseFloat(text);
                return isNaN(value) ? '' : `${Math.floor(value).toLocaleString()} %`;
            }
        }
    ];

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        setIsReadyToLoadChart(false)
        const userIdd = JSON.parse(localStorage.getItem('user')).id
        try {
            console.log(userIdd)
            const values = await form.validateFields();
            console.log("config values: ", values)

            const configData = {
                userId: userIdd,
                businessId: id,
                accounts: allAccountIds.map(account_id => {
                    let minRoas = values[`preference_${account_id.accountId}`];
    
                    // 检查minRoas是否包含小数点，如果没有则加上“.0”
                    if (!minRoas.toString().includes('.')) {
                        minRoas = `${minRoas}.0`;
                    }
                    // minRoas = parseFloat(minRoas);
                    console.log("minROAS: ", minRoas)
    
                    return {
                        accountId: account_id.accountId,
                        minRoas: minRoas // 确保转换为浮点数
                    };
                })
            };
            console.log('Configuration data:', configData);
            // 将配置数据发送到后端保存
            const { data:responseData} = await apiClient.post('/api/businesses/setBusinessConfig', configData);
            console.log("response data: ", responseData.message)
            message.success('Configuration saved successfully');
            setIsModalVisible(false);

            // setIsModalVisible(false);
            setIsConfigured(false);
            setIsConfigured(true);
        } catch (error) {
            console.error('Failed to save configuration:', error);
            showErrorOnce('Failed to save configuration');
        }

        try{
            setLoading(true)
            setAccountsPerformanceLoading(true)
            // 直接调用 fetchAccountsPerformance 并更新表格数据
            const { data:performanceResponse } = await apiClient.post(`/api/businesses/accounts-performance`, { userId: userIdd, businessId: id });
            // const accountsWithTotal = enhanceWithTotalRow(performanceResponse);
            console.log(performanceResponse);

            // 假设 performanceResponse  List<List<Map<String, AccountPerformance>>>
            const originalPerformance = performanceResponse.map(list => list[0].original);
            const convertedPerformance = performanceResponse.map(list => list[1].converted);

            setAccountsPerformance(originalPerformance);  // 设置原始货币数据
            setConvertAccountsPerformance(convertedPerformance);  // 设置转换后的货币数据
            fetchAccountsPerformance();
            fetchOverallPerformance();
            setLoading(false)
            setAccountsPerformanceLoading(false)
            setIsReadyToLoadChart(true)
        }catch(error){
            console.error('Failed to fetch fetchAccountsPerformance:', error);
            // showErrorOnce('Failed to fetch Accounts Performance data!');
        }
    };

    const handleCancel = async() => {
        // form.resetFields(); // 清空表单内容
        setIsModalVisible(false);
    };

    const handleSetTarget = async() => {
        if (targetValue.trim() === '') {
            showErrorOnce('Please enter a target value');
            return;
        }

        const targetPercentage = parseFloat(targetValue);
        if (isNaN(targetPercentage) || targetPercentage <= 0) {
            showErrorOnce('Please enter a valid target percentage greater than 0');
            return;
        }

        // 计算目标值
        const salesYtd = overallPerformance.sales;
        const salesTarget = targetValue
        const targetToClose = (salesTarget - salesYtd);
        const targetAttainment = (salesYtd / salesTarget * 100);

        // 更新目标数据
        const updatedTargetAchievement = [{
            key: '1',
            salesYtd,
            salesTarget,
            targetToClose,
            targetAttainment: `${targetAttainment}%`
        }];

        setTargetAchievement(updatedTargetAchievement);
        // 处理设置目标的逻辑
        console.log('Target value:', targetValue);
        message.success('Target value set successfully');
        
    };

    const renderAccountConfigForm = () => (
        allAccountIds.map(accountId => (
            <Form.Item
                key={accountId.accountId}
                label={`${accountId.accountId} - ${accountId.username || 'No Account Name'}` }
                name={`preference_${accountId.accountId}`}
                rules={[{ required: true, message: 'Please enter the min ROAS!' },
                {
                    validator: (_, value) => {
                        if (isNaN(value)) {
                            return Promise.reject(new Error('Please enter a valid number!'));
                        }
                        if (!/^\d+(\.\d{1})?$/.test(value)) {
                            return Promise.reject(new Error('Please enter a number with at most one decimal place!'));
                        }
                        return Promise.resolve();
                    }
                }
                ]}
            >
                <InputNumber placeholder="Min ROAS" type="number" />
            </Form.Item>
        ))
      );

    const monthlyColumns = [
        { 
            title: <div style={{ textAlign: 'center' }}>Metric</div>, 
            dataIndex: 'metric', 
            key: 'metric',
            align: 'center'  // 添加这一行来居中对齐 Metric 列的数据
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Jan</div>, 
            dataIndex: 'Jan', 
            key: 'jan', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Feb</div>, 
            dataIndex: 'Feb', 
            key: 'feb', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Mar</div>, 
            dataIndex: 'Mar', 
            key: 'mar', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Apr</div>, 
            dataIndex: 'Apr', 
            key: 'apr', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>May</div>, 
            dataIndex: 'May', 
            key: 'may', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Jun</div>, 
            dataIndex: 'Jun', 
            key: 'jun', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Jul</div>, 
            dataIndex: 'Jul', 
            key: 'jul', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Aug</div>, 
            dataIndex: 'Aug', 
            key: 'aug', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Sep</div>, 
            dataIndex: 'Sep', 
            key: 'sep', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Oct</div>, 
            dataIndex: 'Oct', 
            key: 'oct', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Nov</div>, 
            dataIndex: 'Nov', 
            key: 'nov', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
        { 
            title: <div style={{ textAlign: 'center' }}>Dec</div>, 
            dataIndex: 'Dec', 
            key: 'dec', 
            align: 'right',
            render: (value,record) => formatNumber(value, record)
        },
    ];

    function formatNumber(value,record) {
        if (record.metric === 'Sales' || record.metric === 'Target') {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value);
        }

        if (record.metric === 'Attainment (%)') {
            const color = parseFloat(value) >= 100 ? 'green' : 'red';
            return <span style={{ color: color }}>{value}</span>;
        }
        return value; // Returns the original data for other metrics
    }
    
    const handleSetTargets = async () => {
        try {
            setMonthlyDataLoading(true); // 开始加载
            const values = await targetForm.validateFields();
            console.log("set values:" , values)
            setTargets(values);
            //将targets数据存储到后端数据库
            const userId = JSON.parse(localStorage.getItem('user')).id
            const targetsData = {
                userId:userId,
                businessId: id,
                targets: values,
                year: selectedYear
            }
            const { data:save_targets} = await apiClient.post(`/api/businesses/save-targets`, targetsData);
            console.log("save_targets_response: ", save_targets)
            if(save_targets== "Targets saved successfully."){
                message.success("Targets set successful!");
            }
            setIsTargetModalVisible(false);

            const { data:get_monthly_data} = await apiClient.post(`/api/businesses/monthly-data`, targetsData);
            console.log("monthly_data: ", get_monthly_data);
            const data = get_monthly_data;
            const formattedData = [
                { ...data.sales, key: '1', metric: 'Sales' },
                { ...data.targets, key: '2', metric: 'Target' },
                { ...data.attainment, key: '3', metric: 'Attainment (%)' }
            ];
            console.log("formattedData: ",formattedData)
            setMonthlyData(formattedData)
        } catch (error) {
            console.error('Failed to set targets:', error);
        } finally {
            setMonthlyDataLoading(false); // 结束加载
            setIsTargetModalVisible(false);
        }
    };

    const showSetTargetModal = () => {
        setIsTargetModalVisible(true);
    };

    const handleSetTargetCancel = () => {
        setIsTargetModalVisible(false);
    };
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const handleDateRangeChange = async(dates) => {
        setIsReadyToLoadChart(false)
        console.log("dates :" ,dates)
        if (dates && dates.length === 2) {
            const startDate = moment(dates[0].$d).format('YYYY-MM-DD');
            const endDate = moment(dates[1].$d).format('YYYY-MM-DD');
            console.log("startDate&endDate", startDate," ",endDate);
            const userId = JSON.parse(localStorage.getItem('user')).id
            const businessData = {
              userId: userId,
              businessId: id,
              startDate: startDate,
              endDate: endDate
            };
            try{
                const { data:set_select_DateRangeSet} = await apiClient.post(`/business/date-range/save-date-range`, businessData);
                console.log("set_select_DateRangeSet: ",set_select_DateRangeSet)
            }catch(error){
                console.log('Failed to save select date range. error: ', error)
                // 如果后端返回的内容表示失败
                showErrorOnce('Failed to save selected date range.');
            }

            setTopDateRange([moment(startDate), moment(endDate)])
            setDateRangeDefault(false);
            setLoading(true)
            fetchAccountsPerformance();
            // fetchOverallPerformance();
            setIsReadyToLoadChart(true)
        } else {
            setTopDateRange([moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')]);
            setDateRangeDefault(true);
        };
    }

    const renderPeriodText = () => {
        if (!topDateRange || !topDateRange[0] || !topDateRange[1]) {
            return "Please select a date range";
        }
    
        const periodText = `${topDateRange[0].format('YYYY-MM-DD')} - ${topDateRange[1].format('YYYY-MM-DD')}`;
        return dateRangeDefault ? `${periodText} (Default 30 days)` : periodText;
    };

    const renderYearDropdown = (
        <Dropdown 
            overlay={<Menu style={{ maxHeight: 240, overflowY: 'auto' }}>{years}</Menu>} // 设置最大高度和滚动条
            trigger={['click']}
        >
            <Button>{selectedYear} <DownOutlined /></Button>
        </Dropdown>
    );

    // 合并总收益到数据源中
    const getEnhancedDataSource = useMemo(() => {
        if (showConvertedData) {
            const enhancedDataSource = [...convertAccountsPerformance];

            // 计算总收益
            const totalProfit = calculateConvertTotalProfit();
            console.log("enhance total profit: ", totalProfit)

            // 创建总收益行
            const totalRow = {
                key:'total',
                accountId: '', // 该列可为空或显示 "Total"
                accountName: 'Total',
                sales: '',
                investment: 'Total Profit',
                profit: totalProfit, // 移除了额外的格式化，因为 totalProfit 已经是格式化后的字符串
                currencyCode: business.currency
            };

            // 将总收益行添加到数据源中
            enhancedDataSource.push(totalRow);
            return enhancedDataSource;
        } else {
            return accountsPerformance; // 返回不包含总益行的原始数据
        }
    },[showConvertedData, convertAccountsPerformance, business.currency]);

    return (
        <GlobalStyle>
            <WhiteLayout>
                <Content style={{ padding: '20px 50px' }}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <StyledTitle level={2}>Google Business & Market Overview</StyledTitle>
                        </Col>
                        <Col>
                            <Space size="large">
                                <RangePicker
                                    onChange={handleDateRangeChange}
                                    disabledDate={current => {
                                        // 禁用今天之后的日期
                                        const isFuture = current && current > moment().subtract(1, 'days').endOf('day');
                                        // 禁用 2015 年 1 月 1 日之前的日期
                                        const isPast = current && current < moment('2015-01-01').startOf('day');
                                        return isFuture || isPast;
                                    }}
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                />
                            </Space>
                        </Col>
                    </Row>
                </Content>
                <Content style={{ padding: '20px 50px' }}>
                    <InfoCard>
                      <Row gutter={[24, 24]}>
                        <Col xs={24} sm={12} md={8}>
                          <InfoItem>
                            <InfoIcon><IdcardOutlined style={{ fontSize: '36px' }} /></InfoIcon>
                            <InfoContent>
                              <InfoLabel>Business ID</InfoLabel>
                              <InfoValue>{business.id}</InfoValue>
                            </InfoContent>
                          </InfoItem>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          <InfoItem>
                            <InfoIcon><ShopOutlined style={{ fontSize: '36px' }} /></InfoIcon>
                            <InfoContent>
                              <InfoLabel>Name</InfoLabel>
                              <InfoValue>{business.name}</InfoValue>
                            </InfoContent>
                          </InfoItem>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          <InfoItem>
                            <InfoIcon><FileTextOutlined style={{ fontSize: '36px' }} /></InfoIcon>
                            <InfoContent>
                              <InfoLabel>Description</InfoLabel>
                              <InfoValue>{business.description}</InfoValue>
                            </InfoContent>
                          </InfoItem>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          <InfoItem>
                            <InfoIcon><DollarOutlined style={{ fontSize: '36px' }} /></InfoIcon>
                            <InfoContent>
                              <InfoLabel>Currency</InfoLabel>
                              <InfoValue>{business.currency}</InfoValue>
                            </InfoContent>
                          </InfoItem>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          <InfoItem>
                            <InfoIcon><CalendarOutlined style={{ fontSize: '36px' }} /></InfoIcon>
                            <InfoContent>
                              <InfoLabel>Period</InfoLabel>
                              <InfoValue>{renderPeriodText()}</InfoValue>
                            </InfoContent>
                          </InfoItem>
                        </Col>
                      </Row>
                    </InfoCard>
                    
                    <Row justify="end" style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={12} md={8} lg={6} style={{ textAlign: 'right', paddingRight: '24px'}}>
                            <Button type="primary" onClick={showModal}>Configuration</Button>
                        </Col>
                    </Row>


                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    {loading? (
                            <div style={{ textAlign: 'center', padding: '50px 0' }}>
                                <Skeleton size="large" active="true"/> {/* 显示加载指示器 */}
                            </div>
                        ) :  (
                        <div style={{ marginBottom: '24px' }}>
                            <StyledCard>
                            <SectionTitle>Overall Performance</SectionTitle>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={6}>
                                    <StyledDashboardCard>
                                        <Statistic 
                                            title={<Space><FundViewOutlined />Sales</Space>}
                                            value={overallPerformance.sales || 0}
                                            prefix={`${business.currency} `}
                                            formatter={(value) => `${Math.floor(value).toLocaleString()}`}
                                            valueStyle={{ color: '#3f8600' }}
                                        />
                                    </StyledDashboardCard>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <StyledDashboardCard>
                                        <Statistic 
                                            title={<Space><AimOutlined />Investment</Space>}
                                            value={overallPerformance.cost || 0}
                                            prefix={`${business.currency} `}
                                            formatter={(value) => `${Math.floor(value).toLocaleString()}`}
                                            valueStyle={{ color: '#3f8600' }}
                                        />
                                    </StyledDashboardCard>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <StyledDashboardCard>
                                        <Statistic 
                                            title={<Space><CalculatorOutlined />ROAS</Space>}
                                            value={overallPerformance.roas || 0}
                                            precision={2}
                                            valueStyle={{ color: '#3f8600' }}
                                        />
                                    </StyledDashboardCard>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <StyledDashboardCard>
                                        <Statistic 
                                            title={<Space><BankOutlined />Profit</Space>}
                                            value={overallPerformance.profit || 0}
                                            prefix={`${business.currency} `}
                                            formatter={(value) => `${Math.floor(value).toLocaleString()}`}
                                            valueStyle={{ color: '#3f8600' }}
                                        />
                                    </StyledDashboardCard>
                                </Col>
                            </Row>
                            </StyledCard>
                        </div>
                        )}

                        {accountsPerformanceLoading ? (
                            <div style={{ textAlign: 'center', padding: '50px 0' }}>
                                <Skeleton size="large" active="true"/> {/* 显示加载指示器 */}
                            </div>
                        ) :  (
                            <StyledCard>
                                <SectionTitle>Account Performance</SectionTitle>
                                <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                                    <Switch
                                        checked={showConvertedData}
                                        onChange={(checked) => setShowConvertedData(checked)}
                                        checkedChildren={`${business.currency}`}
                                        unCheckedChildren="Original"
                                    />
                                </div>
                                <StyledTable 
                                    columns={showConvertedData ? convert_performance_columns : columns}
                                    rowClassName={(record) => record.key === 'total' ? 'total-row' : ''}
                                    dataSource={getEnhancedDataSource}
                                    pagination={false} 
                                    scroll={{x:'100%'}}
                                    tableLayout='fixed'
                                />
                            </StyledCard>
                        )}

                        {loading ? (
                            <div style={{ textAlign: 'center', padding: '50px 0' }}>
                                <Skeleton size="large" active="true"/> {/* 显示加载指示器 */}
                            </div>
                        ) :  (
                            <StyledCard>
                                <SectionTitle>Target Achievement</SectionTitle>
                                <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                                    <Space>
                                        <Text>{business.currency}</Text> {/* 在输入框前添加显示当前货币 */}
                                        <Input 
                                            placeholder="Enter target" 
                                            value={targetValue}
                                            onChange={(e) => setTargetValue(e.target.value)}
                                            style={{ width: 150 }}
                                        />
                                        <Button 
                                            type="primary" 
                                            onClick={handleSetTarget}
                                        >
                                            Set Target
                                        </Button>
                                    </Space>
                                </div>
                                <StyledTable 
                                    dataSource={targetAchievement} 
                                    columns={targetColumns} 
                                    pagination={false} 
                                    scroll={{x:'100%'}}
                                    />
                            </StyledCard>
                        )}
                        


                        <StyledCard>
                            <SectionTitle>{`Monthly Data (${business.currency})`}</SectionTitle>
                            <div style={{ textAlign: 'right', marginBottom: '16px' }}>
                                <Space>
                                    {renderYearDropdown}
                                    <Button type="primary" onClick={showSetTargetModal} >Set Targets</Button>
                                </Space>    
                            </div>
                            {monthlyDataLoading ? (
                                <div style={{ textAlign: 'center', padding: '50px 0' }}>
                                    <Spin size="large" tip="Data Loading..." />
                                </div>
                            ) : (
                                <StyledTable
                                    columns={monthlyColumns}
                                    dataSource={monthlyData}
                                    pagination={false}
                                    bordered
                                    scroll={{ x: 'max-content' }}
                                />
                            )}
                        </StyledCard>

                        <Modal
                            title="Set Monthly Targets"
                            visible={isTargetModalVisible}
                            onOk={handleSetTargets}
                            onCancel={handleSetTargetCancel}
                            width={800} // 增加模态框宽度以适应两列布局
                        >
                            <Form form={targetForm} layout="vertical">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        {monthNames.slice(0, 6).map((month, index) => (
                                            <Form.Item
                                                key={index + 1}
                                                label={month}
                                                name={index + 1}
                                                initialValue={0}
                                                rules={[
                                                    { required: true, message: 'Please enter a target!' },
                                                    { validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('Please enter a valid number!')) : Promise.resolve()) }
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Enter target" 
                                                    type="number" 
                                                />
                                            </Form.Item>
                                        ))}
                                    </Col>
                                    <Col span={12}>
                                        {monthNames.slice(6).map((month, index) => (
                                            <Form.Item
                                                key={index + 7}
                                                label={month}
                                                name={index + 7}
                                                initialValue={0}
                                                rules={[
                                                    { required: true, message: 'Please enter a target!' },
                                                    { validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('Please enter a valid number!')) : Promise.resolve()) }
                                                ]}
                                            >
                                                <Input 
                                                    placeholder="Enter target" 
                                                    type="number" 
                                                />
                                            </Form.Item>
                                        ))}
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>

                        <div>
                            {isReadyToLoadChart && id  ?(
                                <RevenueChart 
                                    id={id}
                                    startDate={topDateRange[0].format('YYYY-MM-DD')} 
                                    endDate={topDateRange[1].format('YYYY-MM-DD')} 
                                    isDefault={dateRangeDefault}
                                    currency={business.currency}
                                />):(
                                    <div style={{ textAlign: 'center', padding: '50px 0' }}>
                                        <Skeleton size="large" active="true"/> {/* 显示加载指示器 */}
                                    </div>
                                )
                            }
                        </div>
                    </Space>     
                    <Modal
                        title="Configure Account Min ROAS"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        {businessConfigLoading ? (
                            <div style={{ textAlign: 'center', padding: '50px 0' }}>
                                <Skeleton size="large" active="true"/> {/* 显示加载指示器 */}
                            </div>
                        ) :  (
                        <Form form={form} layout="vertical">
                            {renderAccountConfigForm()}
                        </Form>
                        )}
                    </Modal>
                </Content>
            </WhiteLayout>
        </GlobalStyle>
      );
    };

export default BusinessDetail;
