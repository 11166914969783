import { Typography, Form, Input, Button, Checkbox, message } from "antd";
import { useNavigate } from 'react-router-dom';



export default function Login(){

    const navigate = useNavigate();
    const baseURL= process.env.REACT_APP_API_URL;

    const handleLogin = async (values) => {
        const { username, password, remember } = values;
        // 可以选择不发送 remember 字段
        const payload = { username, password };

        console.log(values)
        console.log(payload)
        try{
            const response = await fetch(baseURL+'userlogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error('Incorrect Username or Passowrd!');
            }

            const data = await response.json();
            
            console.log(data.success)
            if (data.success) {
                message.success('Login successful!');
                // 可以在这里处理登录后的路由跳转等
                const userInfo = data.user
                localStorage.setItem("user", JSON.stringify(userInfo))
                localStorage.setItem("token", JSON.stringify(data.token))
                navigate('/welcome'); 
            } else {
                message.error(data.message || 'Login failed');
            }
        }catch(error){
            message.error("Login failed : " + error.message)
        }

    }

    const handleRegisterClick = () => {
        navigate('/register');
    };

    return(<div>
        <Typography.Title>Login Page</Typography.Title>
        
            <div style={{ maxWidth: 300, margin: '0 auto', marginTop: 50 }}>
                <Form
                    name="normal_login"
                    initialValues={{ remember: true }}
                    onFinish={handleLogin}
                >
                    <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please enter your username!' }]}
                    >
                    <Input placeholder="User Name" />
                    </Form.Item>
                    <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please enter your passowrd!' }]}
                    >
                    <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember Me</Checkbox>
                    </Form.Item>
                    </Form.Item>

                    <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Login
                    </Button>
                    </Form.Item>


                    <Form.Item>
                    <Button type="link" block onClick={handleRegisterClick}>
                        Register
                    </Button>
                    </Form.Item>
                    
                </Form>
        </div>
    </div>)
}
