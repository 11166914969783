import React, { useState, useEffect } from 'react';
import { Layout, Typography, Card, Button, Modal, message, Row, Col } from 'antd';
import { GoogleOutlined, FacebookOutlined, LinkedinOutlined, CheckOutlined, DisconnectOutlined } from '@ant-design/icons';
import apiClient from '../../utils/apiClient';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const { Title, Text } = Typography;
const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background: #ffffff;
`;

const StyledContent = styled(Content)`
  padding: 50px;
  max-width: 1000px;
  margin: 0 auto;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-left: 5px solid ${props => props.color || '#1890ff'};

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  .ant-card-body {
    padding: 24px 30px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 20px;
  font-weight: bold;
  background: ${props => props.connected ? 'linear-gradient(45deg, #52c41a, #1890ff)' : 'white'};
  color: ${props => props.connected ? 'white' : '#1890ff'};
  border: ${props => props.connected ? 'none' : '1px solid #1890ff'};

  &:hover {
    background: ${props => props.connected ? 'linear-gradient(45deg, #1890ff, #52c41a)' : '#e6f7ff'};
    color: ${props => props.connected ? 'white' : '#1890ff'};
  }
`;

function BindAdsAccounts() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [currentPlatform, setCurrentPlatform] = useState(null);
    const [isGoogleConnected, setIsGoogleConnected] = useState(false);
    const [isFacebookConnected, setIsFacebookConnected] = useState(false);
    const [isLinkedInConnected, setIsLinkedInConnected] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    const location = useLocation();
    const navigate = useNavigate();
    const baseURL=process.env.REACT_APP_API_URL;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('refresh') === 'true') {
            params.delete('refresh');
            navigate(location.pathname, { replace: true });
            window.location.reload();
        }else{
            checkGoogleAdsAccountAuthorization();
            checkFaceBookAdsAccountAuthorization();
        }
        // const googleConnected = localStorage.getItem('googleAdsConnected') === 'true';
        // setIsGoogleConnected(googleConnected);
        
        // const facebookConnected = localStorage.getItem('facebookAdsConnected') === 'true';
        // setIsFacebookConnected(facebookConnected);
        
        const linkedInConnected = localStorage.getItem('linkedInAdsConnected') === 'true';
        setIsLinkedInConnected(linkedInConnected);
    }, []);

    const checkGoogleAdsAccountAuthorization = async () => {
        try {
            const response = await apiClient.get('/check-authorization');
            setIsGoogleConnected(response.data);
        } catch (error) {
            console.error('Failed to check Google Ads account authorization:', error);
        }
    };

    const checkFaceBookAdsAccountAuthorization = async () => {
        try {
            if (user && user.id){
                const userId = JSON.parse(localStorage.getItem("user")).id;
                const encodedUserId = encodeURIComponent(userId);
                console.log("checkFaceBookAdsAccountAuthorization id: ",encodedUserId)
                const response = await apiClient.get(`/facebook/check-authorization?userID=${encodedUserId}`);
                console.log("checkFaceBookAdsAccountAuthorization response: ",response)
                setIsFacebookConnected(response.data);
            }else{
                message.error("User not found. Please log in first.");
            }
        } catch (error) {
            console.error('Failed to check Facebook Ads account authorization:', error);
        }
    };

    const showModal = (content, platform) => {
        setModalContent(content);
        setCurrentPlatform(platform);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (currentPlatform === 'Google') {
            if (isGoogleConnected) {
                setIsGoogleConnected(false);
                localStorage.removeItem('googleAdsConnected');
            } else {
                setIsGoogleConnected(true);
                localStorage.setItem('googleAdsConnected', 'true');
            }
        } else if (currentPlatform === 'Facebook') {
            if (isFacebookConnected) {
                setIsFacebookConnected(false);
                localStorage.removeItem('facebookAdsConnected');
            } else {
                // setIsFacebookConnected(true);
                // localStorage.setItem('facebookAdsConnected', 'true');
            }
        } else if (currentPlatform === 'LinkedIn') {
            if (isLinkedInConnected) {
                setIsLinkedInConnected(false);
                localStorage.removeItem('linkedInAdsConnected');
            } else {
                // setIsLinkedInConnected(true);
                // localStorage.setItem('linkedInAdsConnected', 'true');
            }
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const bindAccount = async(platform) => {
        if (platform === 'Google') {
            if (isGoogleConnected) {
                showModal('Do you want to disconnect your Google Ads account?', platform);
            } else {
                if (user && user.id){
                    const userId = JSON.parse(localStorage.getItem("user")).id;
                    const encodedUserId = encodeURIComponent(userId);
                    console.log(encodedUserId)
                    window.location.href = baseURL+`google-ads/auth?userID=${encodedUserId}`;
                }else{
                    message.error("User not found. Please log in first.");
                }
            }
        } else if (platform === 'Facebook') {
            if (isFacebookConnected) {
                showModal('Do you want to disconnect your Facebook Ads account?', platform);
            } else {
                // // showModal('Coming Soon! You will able to bound Facebook account!', platform);()
                // const response = apiClient.get(`/facebook/oauth2`)
                // // window.location.href =`http://localhost:8080/api/facebook/oauth2`
                // // window.location.href ="https://www.facebook.com/v20.0/dialog/oauth?client_id=1138657473856818&redirect_uri=http://localhost:3000/orders2&scope=ads_read"
                // window.location.href = response
                try {
                    if (user && user.id){
                    // const response =  await apiClient.get('/facebook/oauth2');
                    // // const { authUrl } = response.authUrl;
                    // console.log("asd",response.data.authUrl)
                    // window.location.href = response.data.authUrl;
                    const userId = JSON.parse(localStorage.getItem("user")).id;
                    const encodedUserId = encodeURIComponent(userId);
                    console.log("facebook encoded id: ",encodedUserId)
                    window.location.href = baseURL+`facebook/oauth2?userID=${encodedUserId}`
                    }else{
                        message.error("User not found. Please log in first.");
                    }
                } catch (error) {
                    console.error('Failed to get Facebook OAuth URL:', error);
                    message.error('Failed to get Facebook OAuth URL');
                }
            }
        } else if (platform === 'LinkedIn') {
            if (isLinkedInConnected) {
                showModal('Do you want to disconnect your LinkedIn Ads account?', platform);
            } else {
                showModal('Coming Soon! You will able to bound LinkedIn account!', platform);
            }
        }
    };

    const handleDisconnect = () => {
        setIsGoogleConnected(false);
        localStorage.removeItem('googleAdsConnected');
        handleOk();
    };

    const handleBind = (platform) => {
        if (platform === 'Google') {
            setIsGoogleConnected(true);
            localStorage.setItem('googleAdsConnected', 'true');
        }
        handleOk();
    };


    const renderPlatformCard = (platform, icon, isConnected, onBindClick, color) => (
        <StyledCard color={color}>
            <Row align="middle" justify="space-between" gutter={24}>
                <Col flex="auto">
                    <Title level={4} style={{ marginBottom: 0, color: color }}>{platform} Ads</Title>
                    <Text type={isConnected ? "success" : "secondary"}>
                        {isConnected ? "Connected" : "Not Connected"}
                    </Text>
                </Col>
                <Col>
                    <StyledButton
                        connected={isConnected}
                        icon={isConnected ? <DisconnectOutlined /> : icon}
                        onClick={() => onBindClick(platform)}
                    >
                        {isConnected ? "Disconnect" : "Connect"}
                    </StyledButton>
                </Col>
            </Row>
        </StyledCard>
    );

    return (
        <StyledLayout>
            <StyledContent>
                {renderPlatformCard("Google", <GoogleOutlined />, isGoogleConnected, bindAccount, "#4285F4")}
                {renderPlatformCard("Facebook", <FacebookOutlined />, isFacebookConnected, bindAccount, "#3b5998")}
                {renderPlatformCard("LinkedIn", <LinkedinOutlined />, isLinkedInConnected, bindAccount, "#0077B5")}
                <Modal 
                    title="Account Binding Status" 
                    visible={isModalVisible} 
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={currentPlatform && currentPlatform.includes('Google') && isGoogleConnected ? 'Disconnect' : 'OK'}
                >
                    {modalContent}
                </Modal>
            </StyledContent>
        </StyledLayout>
    );
}

export default BindAdsAccounts;
