import styled from 'styled-components';
import { Card } from 'antd';

const StyledCard = styled(Card)`
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  margin-bottom: 24px;

  .ant-card-head {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-card-body {
    padding-top: 16px;
  }

  .ant-table-thead > tr > th {
    background: white;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    border-bottom: 1px solid #f0f0f0;
    padding: 16px 16px;
  }

  .ant-table-thead > tr > th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table-thead > tr > th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
`;

export default StyledCard;