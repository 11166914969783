import { Typography } from "antd";
import React, { useState, useEffect } from 'react';
import apiClient from '../../utils/apiClient'


function Platform(){
    const [userName, setUserName] = useState('');
    const [dashboardData, setDashboardData] = useState('');
    

    // Effect to retrieve user info from localStorage
    useEffect(() => {
        const userData = localStorage.getItem('user');
        apiClient.get('checkid')
        .then(response => {
            setDashboardData(response.data.user);
        })
        .catch(error => {
            console.error('Error fetching data: ', error);
        });
        if (userData) {
            const user = JSON.parse(userData);
            setUserName(user.username); // Set user name from localStorage
        }
    }, []); // Empty dependency array to run only on component mount
    return(<div>
        <Typography.Title>Platforms Page</Typography.Title>
        <h1>Hello, {userName}!</h1>
        {dashboardData ? (
                <div>
                    <h2>Dashboard Data:</h2>
                    <p>Email: {dashboardData}</p>
                </div>
            ) : (
                <p>Loading dashboard data...</p>
            )}
    </div>)
}

export default Platform;