import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Statistic, Button, message } from 'antd';
import { UserOutlined, RiseOutlined, AimOutlined, ApiOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../utils/apiClient'; // 添加这行

const { Title, Paragraph } = Typography;

const StyledLayout = styled.div`
  min-height: 100vh;
  background: #ffffff;
  padding: 50px;
`;

const StyledCard = styled(Card)`
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-top: 4px solid ${props => props.color || '#1890ff'};
`;

const GradientButton = styled(Button)`
  background: linear-gradient(45deg, #1890ff, #36cfc9);
  border: none;
  color: white;
  &:hover {
    background: linear-gradient(45deg, #36cfc9, #1890ff);
    color: white;
  }
`;

const WelcomeTitle = styled(Title)`
  color: #1890ff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

export default function Welcome() {
    const [userName, setUserName] = useState('');
    const [businessCount, setBusinessCount] = useState(0);
    const [conversionRate, setConversionRate] = useState(0);
    const navigate = useNavigate(); // 添加这行

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const user = JSON.parse(userData);
            setUserName(user.username);
            fetchBusinessAmount(user.id); // 传递 userId 给 fetchBusinessAmount
        }
        // 模拟获取数据
        setConversionRate(3.7);
    }, []);

    const fetchBusinessAmount = async (userId) => {
        try {
            const response = await apiClient.post('/api/businesses/amount', { userId });
            setBusinessCount(response.data);
        } catch (error) {
            console.error('Failed to fetch business amount:', error);
            message.error('Failed to fetch business amount');
        }
    };

    // 删除 fetchConnectedPlatforms 函数

    const handleCreateBusiness = () => {
        navigate('/business');
    };

    const handleManageAdsPlatforms = () => {
        navigate('/bind');
    };

    return (
        <StyledLayout>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <StyledCard color="#1890ff">
                        <WelcomeTitle level={2}>Welcome back, {userName}!</WelcomeTitle>
                        <Paragraph>
                            It's great to see you again. Here's an overview of your recent performance.
                        </Paragraph>
                    </StyledCard>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <StyledCard color="#52c41a">
                        <Statistic
                            title="Active Business"
                            value={businessCount}
                            prefix={<AimOutlined style={{ color: '#52c41a' }} />}
                        />
                    </StyledCard>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <StyledCard color="#faad14">
                        <Statistic
                            title="Conversion Rate"
                            value={conversionRate}
                            precision={2}
                            suffix="%"
                            prefix={<RiseOutlined style={{ color: '#faad14' }} />}
                        />
                    </StyledCard>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <StyledCard color="#eb2f96">
                        <GradientButton icon={<UserOutlined />} size="large" block>
                            View Profile
                        </GradientButton>
                    </StyledCard>
                </Col>
                <Col span={24}>
                    <StyledCard color="#722ed1">
                        <Title level={4}>Quick Actions</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <GradientButton block onClick={handleCreateBusiness}>Create Business</GradientButton>
                            </Col>
                            <Col span={12}>
                                <GradientButton block onClick={handleManageAdsPlatforms}>Manage Ads Platforms</GradientButton>
                            </Col>
                        </Row>
                    </StyledCard>
                </Col>
            </Row>
        </StyledLayout>
    );
}