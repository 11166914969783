import axios from 'axios';

// 创建 axios 实例
const apiClient = axios.create({
    // baseURL: 'http://localhost:8080/api/',
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 240000, // 设置请求的超时时间为30秒
    headers: {
        'Content-Type': 'application/json',
    },
});

// 请求拦截器，附加 JWT 令牌
apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        console.log('Sending token:', token);  // 打印令牌
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// 响应拦截器，处理 401 错误
apiClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        // 处理未授权错误，比如重定向到登录页面
        window.location.href = '/login';
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }
    return Promise.reject(error);
});

export default apiClient;