import Backend from "./Backend/backend";
import Login from "./Login";
import Register from "./Register";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./privateRout";
import OAuth2SuccessHandler from "../utils/OAuth2SuccessHandler"
import AdminLogin from "../pages/Admin/AdminLogin";
import AdminRoute from "./adminRouts";
import AdminHome from "../pages/Admin/adminHome";


function EntryRouts(){
    return(
        <Routes>
            <Route path="/login" element={<Login/>}></Route>
            <Route path="/register" element={<Register/>}></Route>
            <Route path="/alibaba-getdynamic-login" element={<AdminLogin/>}></Route>
            <Route path="/*" element={<PrivateRoute />}>
                <Route path="*" element={<Backend/>}></Route>
            </Route>
            {/* <Route path="/*" element={<Backend/>}></Route> */}
            <Route path="/oauth2/success" element={<OAuth2SuccessHandler></OAuth2SuccessHandler>}></Route>
            <Route path="/gateway" element={<AdminRoute/>}>
                <Route path="check" element={<AdminHome/>}/>
            </Route>
        </Routes>
        )
}

export default EntryRouts;