import { Menu } from "antd"
import{LineChartOutlined,AppstoreAddOutlined,UngroupOutlined,ShoppingOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";



function AppSideBar(){
    const navigate = useNavigate();
    return <div className="AppSideBar" style={{ height: '100%', width: '100%' }}>
      <Menu
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
            onClick={(item)=>{
                navigate(item.key);
            }}
            items={[
                // {
                //     label: "DashBoard",
                //     icon: <LineChartOutlined />,
                //     key:'/dashboard'
                // },
                // {
                //     label: "Platform",
                //     icon:<AppstoreAddOutlined />,
                //     key:'/platform'
                // },
                {
                    label: "Ads Platform",
                    icon:<AppstoreAddOutlined />,
                    key:'/bind'
                },
                {   
                    label: "Business",
                    icon:<ShoppingOutlined />,
                    key:'/business'
                },
                // {
                //     label: "Inventory",
                //     icon:<UngroupOutlined />,
                //     key:'/inventory'
                // },
                // {   
                //     label: "Orders",
                //     icon:<ShoppingOutlined />,
                //     key:'/orders'
                // },
                {   
                    label: "Facebook",
                    icon:<ShoppingOutlined />,
                    key:'/facebook'
                }
            ]}
        ></Menu>
    </div>
}

export default AppSideBar