import { Route, Routes } from "react-router-dom";
import DashBoard from "../../pages/Dashboard";
import Inventory from "../../pages/Inventory";
import Orders from "../../pages/Orders";
import Platform from "../../pages/platforms";
import { NavigationBar } from "../../homePage/navigationBar";
import Welcome from "../../pages/Welcome";
import BindAdsAccount from "../../pages/BindAdsAccount";
import BusinessList from "../../pages/Business/business";
import BusinessDetail from "../../pages/BusinessDetails";
import Facebook from "../../pages/Facebook";
import AdminHome from "../../pages/Admin/adminHome";




function AppRoutes(){

    
    return(
        <Routes>
            <Route path="welcome" element={<Welcome></Welcome>}></Route>
            {/* <Route path="dashboard" element={<DashBoard/>}></Route> */}
            {/* <Route path="inventory" element={<Inventory/>}></Route> */}
            {/* <Route path="orders" element={<Orders/>}></Route> */}
            {/* <Route path="platform" element={<Platform/>}></Route> */}
            {/* <Route path="home" element={<NavigationBar></NavigationBar>}></Route> */}
            <Route path="bind" element={<BindAdsAccount></BindAdsAccount>}></Route>
            <Route path="business" element={<BusinessList></BusinessList>}></Route>
            <Route path="business/:id" element={<BusinessDetail/>} />
            <Route path="facebook" element={<Facebook></Facebook>}></Route>
        </Routes>
        )
}

export default AppRoutes;