import { Typography , List} from "antd";
import React, { useEffect, useState } from "react";
import apiClient from "../../utils/apiClient";


function Facebook(){
    const [accountIds, setAccountIds] = useState([]);
    const [error, setError] = useState("");
    const userId = JSON.parse(localStorage.getItem("user")).id;

    useEffect(() => {
        const fetchAccountIds = async () => {
          try {
            console.log("userid: ",userId)
            const response = await apiClient.get(`/facebook/getAccounts?userID=${userId}`);
            console.log("response", response.data)
            if (response.data) {
              setAccountIds(response.data);
            } else if (response.data.error) {
              setError(response.data.error);
            }
          } catch (error) {
            setError("Error fetching account IDs");
          }
        };
        fetchAccountIds();
    }, []);


    return(
        <div>
        <content>
          {/* <Typography.Title level={2}>Business Dashboard</Typography.Title> */}
        <Typography.Title level={2}>Facebook Page</Typography.Title>
        {error ? (
          <Typography.Text type="danger">{error}</Typography.Text>
        ) : (
          <List
            header={<div>Account IDs</div>}
            bordered
            dataSource={accountIds}
            renderItem={item => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
        )}
        </content>
      </div>
    )
}

export default Facebook;