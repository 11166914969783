import "./App.css"
// import { Route, Routes } from "react-router-dom";
// import Login from "./entryApp/Login"
// import Backend from "./entryApp/Backend/backend"
// import Register from "./entryApp/Register";
// import AppContent from "./compoents/AppContent";
// import AppHeader from "./compoents/AppHeader";
import EntryRouts from "./entryApp/entryRouts";

function App(){
  return <div className="App">
    <EntryRouts></EntryRouts>
  </div>
}

export default App