import {Layout} from "antd"
import AppHeader from "../../compoents/AppHeader"
import AppSideBar from "../../compoents/AppSideMenue"
import AppContent from "../../compoents/AppContent"
import AppFooter from "../../compoents/AppFooter"

const { Header, Sider, Content, Footer } = Layout;

function Backend(){
  return(
    <Layout className="Backend" style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#fff', padding: 0, position: 'fixed', width: '100%',zIndex: 1 }}>
        <AppHeader />
      </Header>
      <Layout style={{ flex: 1 , marginTop: 60}}>
        <Sider width={200} style={{  background: '#fff', position: 'fixed', height: '100vh', overflow: 'auto'}}>
          <AppSideBar />
        </Sider>
        <Layout style={{ marginLeft: 200, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Content style={{ flex: '1 0 auto', margin: 0, padding: '24px 24px 0', background: '#fff' }}>
            <AppContent />
          </Content>
          <Footer style={{ textAlign: 'center', padding: '0 24px', background: '#fff' ,flexShrink: 0}}>
            <AppFooter />
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Backend