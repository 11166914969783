import React, { useState, useEffect } from 'react';
import { Layout, Menu, Dropdown, Space, Avatar, Typography } from 'antd';
import { UserOutlined, DownOutlined, LogoutOutlined, HomeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;
const { Text } = Typography;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background: linear-gradient(90deg, #1890ff 0%, #096dd9 100%);  // 蓝色渐变
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 16px;
`;

const CompanyName = styled(Text)`
  color: white;
  font-size: 18px;
  font-weight: bold;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled(Text)`
  color: white;
  margin-right: 8px;
`;

function AppHeader() {
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<HomeOutlined />}>
        <a href="https://test.getdynamic.io">Home</a>
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} danger onClick={logout}>
        Log out
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      setUserName(user.username);
    }
  }, []);

  return (
    <StyledHeader>
      <Logo>
        <LogoImage src={`${process.env.PUBLIC_URL}/gd_logo.png`} alt="GetDynamic Logo" />
        <CompanyName>GetDynamic</CompanyName>
      </Logo>
      <UserInfo>
        <UserName>{userName || 'Loading...'}</UserName>
        <Dropdown overlay={menu} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#fff', color: '#1890ff' }} />
              <DownOutlined style={{ color: 'white' }} />
            </Space>
          </a>
        </Dropdown>
      </UserInfo>
    </StyledHeader>
  );
}

export default AppHeader;