import React, { useState } from 'react';
import { Layout, Button, notification } from 'antd';
import apiClient from '../../utils/apiClient';

const { Header, Sider, Content, Footer } = Layout;

function AdminHome(){
    const [loading, setLoading] = useState(false);

    const handleLoadCurrencyData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token'); // 假设你将JWT token存储在localStorage中
        const requestData={
            startDate: '2020-01-01',
            endDate: '2024-08-31',
            list: "CNY"
          }
        const response = await apiClient.post('/api/alibaba-gateway/currency/load', requestData);
  
        notification.success({
          message: 'Success',
          description: 'Currency data has been loaded successfully!',
        });
      } catch (error) {
        console.error('Error loading currency data:', error);
        notification.error({
          message: 'Error',
          description: 'Failed to load currency data.',
        });
      } finally {
        setLoading(false);
      }
    };

    const handleEncryptTokens = async () => {
      try {
        const response = await apiClient.get('/encryptExistTokens');
        if (response.status === 200) {
          notification.success('Tokens have been successfully encrypted!');
        } else {
          notification.error('Failed to encrypt tokens!');
        }
      } catch (error) {
        console.error('Error while encrypting tokens:', error);
        notification.error('Error while encrypting tokens!');
      }
    };


  return(
    <Layout className="Backend" style={{ minHeight: '100vh' }}>
      <Header>
        <h1>Hello Admin</h1>
      </Header>
      <Content style={{ padding: '20px' }}>
        <Button
          type="primary"
          onClick={handleLoadCurrencyData}
          loading={loading}
        >
          Load Currency Data
        </Button>
        <Button type="primary" onClick={handleEncryptTokens}>
          Encrypt Existing Tokens
        </Button>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Admin Panel ©2024 Created by Your Company
      </Footer>
    </Layout>
  );
}

export default AdminHome