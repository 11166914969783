import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function OAuth2SuccessHandler() {
    const navigate = useNavigate();

    useEffect(() => {
        // Set googleAdsConnected to true in localStorage
        // localStorage.setItem('googleAdsConnected', 'true');

        const params = new URLSearchParams(window.location.search);
        const platform = params.get('platform');

        if (platform === 'google') {
            localStorage.setItem('googleAdsConnected', 'true');
        } else if (platform === 'facebook') {
            localStorage.setItem('facebookAdsConnected', 'true');
        }
        
        // Redirect to the desired page after setting the localStorage item
        navigate('/bind?refresh=true');
    }, []);

    return <div>Loading...</div>;
}

export default OAuth2SuccessHandler;